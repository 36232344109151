
















import { SetupWizardService, Step, StepDirection } from '@/services/setup-wizard-service';
import { useSetupWizardStore } from '@/stores/setup-wizard';
import { computed, defineComponent } from '@vue/composition-api';

const validIcon = 'mdi-check';
const invalidIcon = 'mdi-times';

export default defineComponent({
  name: 'SetupNavigation',
  setup(props, context) {
    const store = useSetupWizardStore();

    const loading = computed(() => store.loading);
    const steps = computed(() => SetupWizardService.mainSteps);
    const nextStep = computed(() => SetupWizardService.nextStep?.name);
    const canGoNext = computed(() => (nextStep.value && !loading.value ? SetupWizardService.validateRoutes() : false));

    const stepIcon = (step: Step) => {
      switch (step.direction) {
        case StepDirection.prev:
          return step.valid ? validIcon : invalidIcon;
        default:
          return '';
      }
    };
    const activeIcon = (step: Step) => {
      switch (step.direction) {
        case StepDirection.curr:
          return 'mdi-circle-medium';
        default:
          return 'mdi-checkbox-blank-circle-outline';
      }
    };
    const stepClass = (step: Step) => {
      const active = step.direction === StepDirection.curr ? 'active' : '';
      if (canGoNext.value) {
        return `called ${active}`;
      }
      return active;
    };

    const goTo = (name: string) => {
      const route = context.root.$route;
      if (name !== route.name) {
        SetupWizardService.navigate(name);
      }
    };

    return {
      steps,
      stepIcon,
      activeIcon,
      stepClass,
      goTo,
    };
  },
});
