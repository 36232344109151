


















import { useSetupWizardStore } from '@/stores/setup-wizard';
import AppMessages from '@/components/shared/message/Messages.vue';
import AppLoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import AppSetupNavigation from '@/components/wizard/navigation/SetupNavigation.vue';
import { defineComponent, computed, onMounted, onBeforeUnmount } from '@vue/composition-api';
import { SetupWizardService } from '@/services/setup-wizard-service';
import i18n from '@/plugins/i18n';
import { SETUP_REPOSITORY, SETUP_SUMMARY } from '@/utils/const';

export default defineComponent({
  name: 'SetupWrapper',
  components: {
    AppMessages,
    AppLoadingSpinner,
    AppSetupNavigation,
  },
  setup(props, { root }) {
    onMounted(() => {
      SetupWizardService.initStores();
    });
    onBeforeUnmount(() => {
      SetupWizardService.resetStores();
    });

    const store = useSetupWizardStore();
    const loading = computed(() => store.loading);
    const loadingMessage = computed(() => {
      if (root.$route.name === SETUP_SUMMARY) {
        return i18n.t('setup.create.loadingMessage');
      }
      if (root.$route.name === SETUP_REPOSITORY) {
        if (store.detectionResults && store.detectionResults.length > 0) {
          return `${store.detectionResults.length} ${i18n.t('setup.frameworkSelection.detectedMessage')}`;
        }
        return i18n.t('setup.frameworkSelection.loadingMessage');
      }
      return '';
    });

    const isLastStep = computed(() => SetupWizardService.isLastStep);

    return {
      loading,
      loadingMessage,
      isLastStep,
    };
  },
});
